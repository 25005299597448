/* Importing Bootstrap SCSS file. */
@import "~bootstrap/scss/bootstrap";
@import "~ngx-ui-switch/ui-switch.component";

/* You can add global styles to this file, and also import other style files */
.modal-holder {
  animation-name: example;
  animation-duration: 0.3s;
}

@keyframes example {
  0% {
    transform: scale(0.5);
  }

  100% {
    transform: scale(1);
  }
}

.carousel-item {
  transition: opacity 0.7s ease !important;
  position: absolute !important;
  display: block !important;
  opacity: 0;
}

.carousel-item.active {
  position: relative !important;
  opacity: 1;
}

.container-fluid::-webkit-scrollbar,
as-split-area .p-datatable-wrapper::-webkit-scrollbar,
as-split-area::-webkit-scrollbar {
  width: 8px !important;
  height: 9px !important;
}

.container-fluid::-webkit-scrollbar-track,
as-split-area .p-datatable-wrapper::-webkit-scrollbar-track,
as-split-area::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(85, 110, 230, 0.25) !important;
  border-radius: 10px !important;
}

.container-fluid::-webkit-scrollbar-thumb,
as-split-area .p-datatable-wrapper::-webkit-scrollbar-thumb,
as-split-area::-webkit-scrollbar-thumb {
  border-radius: 10px !important;
  -webkit-box-shadow: inset 0 0 6px rgba(#2a3042, 0.25) !important;
}

.pagination {
  margin-bottom: 0;
}

*::selection {
  background: #a8d1ff;
}
*::-moz-selection {
  background: #a8d1ff;
}

.p-datatable-sm,
.p-datatable-wrapper {
  height: 100%;
}

.p-datatable .p-datatable-thead > tr > th {
  white-space: nowrap;
  font-size: 14px;
}

.p-datatable .p-datatable-tbody > tr:focus,
.p-datatable .p-datatable-tbody > tr.selected {
  outline: 0.15rem solid #a6d5fa !important;
  outline-offset: -0.15rem !important;
}

.p-datatable.p-datatable-sm .p-datatable-tbody > tr > td {
  padding: 0.3rem 0.5rem !important;
  white-space: nowrap;
}

.p-inputnumber-buttons-stacked .p-inputnumber-input {
  width: 100px !important;
}

p-inputnumber {
  height: 30px;
}

ngb-modal-backdrop {
  z-index: 1050 !important;
}

tbody td {
  vertical-align: middle !important;
  padding-top: 0.1rem !important;
  padding-bottom: 0.1rem !important;
  padding-left: 0.6rem !important;
  padding-right: 0.6rem !important;
}
